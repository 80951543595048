import React from 'react';
class FooterBannerPen extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
            isVisible: true
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
      if (this.props.clickHandler) {
        this.props.clickHandler();
        event.preventDefault();
        event.stopPropagation();
      }
    }

    handleClose() {
        this.setState({ isVisible: false });
    }

    render() {
      if (!this.state.isVisible) {
        return null;
      }
      
      return (
            <div className="ctabanner footer-cta-banner pen" id="ctabanner"  aria-labelledby="footerbanner">
                <div className="info">
                   <div className="sixty">
                    <img src="/img/com-semi-graphic-pen.png" alt="Compounded Semaglutide" className="pen" />
                    <h4>Struggling to find Ozempic, Wegovy, Mounjaro, or Zepbound?</h4>
                                            
                  </div>
                  <div className="fourty">
                    <a href="/weight-loss-program" onClick={this.handleClick} className="btn green" target='_blank' rel="noopener noreferrer">See&nbsp;If&nbsp;You&nbsp;Qualify</a>
                  </div>
                </div>
                       
           <button type="button" className="close" data-dismiss="ctabanner" aria-label="Close" onClick={this.handleClose}>
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
        )
    }
}

export default FooterBannerPen;